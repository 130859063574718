/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var PhilippeMcIntyre = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        window.sr = ScrollReveal();
        sr.reveal('.post-block-image__thumbnail', { 
          duration: 800, 
          viewFactor : 0.2, 
          scale: 0.2,
          origin: 'bottom',
          distance    : '200px',
          mobile      : false,
         
        });

        var $overlayGallery = $('.overlay-gallery'),
            $overlayGalleryCont = $overlayGallery.find('.swiper-container'),
            $overlayGalleryCaption = $overlayGallery.find('.swiper-description'),
            $overlayQuote = $('.overlay-quote'),
            $overlayQuoteCont = $overlayQuote.find('.overlay-quote__content'),
            $overlayVideo = $('.overlay-video'),
            $overlayVideoCont = $overlayVideo.find('.embed-responsive-16by9'),
            slider;

          $('.overlay').on('click', '.close-overlay', function(e) {
            if( !$('body').hasClass('single') ) {
              var $overlay = $(e.delegateTarget);
              $overlay.removeClass('active');

              $overlayVideoCont.empty();
              $.scrollLock(false);
              e.preventDefault();
            }
          });   


           var mySVGsToInject = document.querySelectorAll('img.svg');
           // Do the injection
           new SVGInjector(mySVGsToInject);


        $('.posts').on('click', '.post-block .image-content__title, .post-block .image-content__number, .post-block .post-block-image__thumbnail, .post-block-quote__wrapper', function(e) {
          var $block = $(this).parents('.post-block'),
              template = $block.find('script').html(),
              type = $block.attr('data-type'),
              bgcolor = $block.attr('data-bgcolor'),
              textcolor = $block.attr('data-textcolor');

          $.scrollLock(true);

          if(type == 'beeldserie') {
            $overlayGalleryCont.find('.swiper-wrapper').remove();
            $overlayGalleryCont.prepend(template);
            $overlayGallery.css({background: bgcolor});
            $overlayGallery.addClass('active');


            if(typeof slider !== 'undefined') {
              slider.destroy();
            }

            slider = new Swiper ('.swiper-container', {
              loop: true,
              spaceBetween: 30,
              centeredSlides: true,
              autoplay: false,
              autoplayDisableOnInteraction: false,
              preloadImages: false,
              lazyLoading: true,
              lazyLoadingInPrevNext: true,
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              pagination: '.swiper-pagination',
              paginationType: 'custom',
              paginationClickable: true,
              onInit: function(swiper){
                var description = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();
                $overlayGalleryCaption.html(description);
              },
              onSlideChangeStart: function(swiper) {
                var description = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();
                $overlayGalleryCaption.html(description);
              },
              paginationCustomRender: function (swiper, current, total) {
                  //return ("%03d\n", current) + ("<br>") + total;
                  if(current < 10) {
                    current = '0' + current;
                  }

                  if(total < 10) {
                    total = '0' + total;
                  }


                  return '<span class="current">' + current + '<br>' + '</span><span class="total">' + total + '</span>';
              }

            });
          }

          if(type == 'citaat') {
            $overlayQuoteCont.empty();
            $overlayQuoteCont.prepend(template);
            $overlayQuote.css({background: bgcolor});
            $overlayQuote.addClass('active');
          }


           if(type == 'video') {
            $overlayVideoCont.empty();
            $overlayVideoCont.prepend(template);
            $overlayVideo.css({background: bgcolor});
            $overlayVideo.addClass('active');
          }

          e.preventDefault();

        });




        $(window).scroll(function(){ 
           if ($(this).scrollTop() > 100) { 
               $('.arrowup-wrapper').fadeIn(); 
           } else { 
               $('.arrowup-wrapper').fadeOut(); 
           } 
        }); 


        $('.arrowup-wrapper').click(function(){ 
           $("html, body").animate({ scrollTop: 0 }, 600); 
           return false; 
        }); 


        $("a[href='#top']").click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });


        var str = "Philippe McIntyre ",
            i = 0,
            isTag,
            text;

        (function type() {
            text = str.slice(0, ++i);
            if (text === str) return;

            document.getElementById('typewriter-header').innerHTML = text;

            var char = text.slice(-1);
            if( char === '<' ) isTag = true;
            if( char === '>' ) isTag = false;

            if (isTag) return type();
            setTimeout(type, 80);
        }());


        $('.posts').infinitescroll({
          loading: {
            finished: undefined,
            finishedMsg: "Er zijn geen oudere berichten meer",
            img: WPURLS.templateurl + '/dist/images/ajax-loader.gif',
            msg: null,
            msgText: "Berichten laden",
            selector: '.wrapper-load-more',
            speed: 0,
            start: undefined
          },
          itemSelector: '.post-block',
          nextSelector: '.post-nav .previous a',
          navSelector: '.post-nav'
          },
          function(){ 
              sr.sync();
          }
        );


       


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single': {
      init: function() {
        var slider = new Swiper ('.swiper-container', {
          loop: true,
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: false,
          autoplayDisableOnInteraction: false,
          preloadImages: false,
          lazyLoading: true,
          lazyLoadingInPrevNext: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          pagination: '.swiper-pagination',
          paginationType: 'custom',
          paginationClickable: true,
          onSlideChangeStart: function(swiper) {
            var description = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();
            $('.swiper-description').html(description);
          },
          paginationCustomRender: function (swiper, current, total) {
              //return ("%03d\n", current) + ("<br>") + total;
              if(current < 10) {
                current = '0' + current;
              }

              if(total < 10) {
                total = '0' + total;
              }


              return '<span class="current">' + current + '<br>' + '</span><span class="total">' + total + '</span>';
          }

        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = PhilippeMcIntyre;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
